<template>
    <div class="chat-wrapper" id="chat-ai">
        <div class="chat-container-wrapper">
            <div class="chat-container">
                <div class="lg:h-[48px]">
                    <MainHeader :is-sticky="true" />
                </div>

                <BurgerMenu />

                <div class="chat__sub-title">
                    Smarter daily living<br />with personal<br />AI assistant
                </div>

                <div class="chat__powered-by-chat">Chat on GPT</div>

                <div class="chat__hint">
                    GPT - generative pre-trained transformer is a prominent<br
                        class="lg:flex hidden"
                    />framework for generative artificial intelligence.
                </div>

                <div class="chat__rating">
                    <Rating />
                    <div class="chat__rating-text">56k ratings</div>
                </div>
                <div class="chat__button-wrapper">
                    <button
                        class="chat__button"
                        @click="getStartedButtonClicked"
                    >
                        Get Started
                    </button>
                </div>
                <div
                    class="video-background"
                    data-aos="fade-in"
                    data-aos-easing="ease-in-out"
                    data-aos-anchor=".chat-container"
                    data-aos-duration="1500"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import {trackEvent} from '@/Utils/analytics.js'
import {START_NOW_LINK} from '@/Components/menuItems.js'

import Rating from '@/Icons/chat/Rating.vue'
import MainHeader from '@/Components/MainHeader.vue'
import BurgerMenu from '@/Components/BurgerMenu.vue'

export default {
    data() {
        return {
            startNowLink: START_NOW_LINK,
        }
    },
    components: {
        MainHeader,
        Rating,
        BurgerMenu,
    },
    props: {
        videoUrl: {
            type: String,
            required: true,
        },
    },
    methods: {
        getStartedButtonClicked() {
            trackEvent('start_page_pressed')
            trackEvent('get_started', null, 'fb')

            if (this.user) {
                this.$inertia.visit(route('chat.index'))
            } else {
                this.$inertia.visit(this.startNowLink)
            }
        },
    },
    computed: {
        user() {
            return this.$page.props.auth.user
        },
    },
}
</script>

<style lang="scss" scoped>
.chat-wrapper {
    @apply w-full bg-[#0F1011];
}

.chat-container-wrapper {
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: -webkit-image-set(
        url('../../../../resources/images/chat/375/chat-bg.svg') 1x
    );
    background-image: image-set(
        url('../../../../resources/images/chat/375/chat-bg.svg') 1x
    );

    @screen lg {
        @apply w-full max-w-[1920px] mx-auto;
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: 1060px 839px;
        background-image: -webkit-image-set(
            url('../../../../resources/images/chat/1920/chat-bg.svg') 1x
        );
        background-image: image-set(
            url('../../../../resources/images/chat/1920/chat-bg.svg') 1x
        );
    }
}

.chat-container {
    @apply flex flex-col items-center bg-[#0F1011] w-[375px] mt-0 m-auto pt-[120px] relative px-[20px];
    background: transparent;
    background-repeat: no-repeat;
    background-position: bottom 60px center;
    background-image: -webkit-image-set(
        url('../../../../resources/images/chat/375/video-bg-2.webp') 1x
    );
    background-image: image-set(
        url('../../../../resources/images/chat/375/video-bg-2.webp') 1x
    );
    background-size: 360px 260px;
    @screen lg {
        background: transparent;
        background-repeat: no-repeat;
        background-position: top 198px right 20px;
        background-image: -webkit-image-set(
            url('../../../../resources/images/chat/1920/video-bg.webp') 1x
        );
        background-image: image-set(
            url('../../../../resources/images/chat/1920/video-bg.webp') 1x
        );
        background-size: 690px 510px;
        @apply block w-[1280px] pt-[36px] mx-auto;
    }
}

.video-background {
    @apply absolute bottom-[10px] right-[27px];
    @screen lg {
        @apply bottom-[20px] right-[50px];
    }
}

.chat__sub-title {
    @apply font-sans text-[32px] font-normal leading-[38px] tracking-[0px] text-center text-white font-[700];
    @screen lg {
        @apply mt-[50px] text-[60px] leading-[70px] text-left;
    }
}

.chat__powered-by-chat {
    @apply relative mt-[16px] py-[5px] px-[39px] w-[280px] h-[64px] rounded-[100px] bg-white text-[32px] font-[700] text-black;

    &::before {
        @apply w-[280px];
        content: '';
        position: absolute;
        top: -8px;
        left: -8px;
        height: 80px;
        width: 295px;
        border-radius: 50px;
        padding: 8px;
        background: linear-gradient(
            135.5deg,
            rgba(255, 255, 255, 0.0001) 0%,
            rgba(255, 255, 255, 0.3) 50.81%,
            rgba(255, 255, 255, 0.0001) 99.15%
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
}

.chat__hint {
    @apply mt-[32px] text-[18px] text-center leading-[24px] text-white opacity-75;
    @screen lg {
        @apply text-left;
    }
}

.chat__rating {
    @apply flex flex-col items-center gap-[4px] mt-[32px];
    @screen lg {
        @apply flex-row gap-[20px];
    }
}

.chat__rating-text {
    @apply font-sans text-[16px] font-normal leading-[22px] tracking-[0px] text-center text-white;
    @screen lg {
        @apply text-[18px];
    }
}

.chat__button-wrapper {
    @apply flex justify-center mt-[32px] pb-[360px] w-full;
    @screen lg {
        @apply mt-[32px] pb-[151px] justify-start w-[360px];
    }
}

.chat__button {
    @apply font-sans max-w-[360px] min-w-full h-[74px] text-[20px] font-bold leading-[26px] tracking-[0px] text-center text-white text-[22px] rounded-[16px];
    background: linear-gradient(90deg, #0fab6d 0%, #6c3da9 100%);
    box-shadow: 0 0 4px 0 #00000018;
    transition: background 1s ease-out;

    &:hover {
        background: linear-gradient(270deg, #0fab6d 0%, #6c3da9 100%);
    }
}
</style>
