<template>
    <DefaultLayout>
        <Head title="CHATOPEN" />
        <div class="container-main">
            <ChatGptAI :video-url="videoUrl" />
            <!-- <Features /> -->
            <GetHelp />
            <Reviews />
            <EmbraceFuture />
            <AllNeeds />
            <BaseFooter />
        </div>
    </DefaultLayout>
</template>

<script>
import Cookies from 'js-cookie'
import {Head} from '@inertiajs/vue3'
import {trackEvent} from '@/Utils/analytics.js'

import DefaultLayout from '@/Layouts/DefaultLayout.vue'
import BaseFooter from '@/Components/Global/BaseFooter.vue'
import Reviews from '@/Components/IndexLanding/Reviews.vue'
import GetHelp from '@/Components/IndexLanding/GetHelp.vue'
// import Features from '@/Components/IndexLanding/Features.vue'
import AllNeeds from '@/Components/IndexLanding/AllNeeds.vue'
import EmbraceFuture from '@/Components/IndexLanding/EmbraceFuture.vue'
import ChatGptAI from '@/Components/Temporary/ChatGptAI.vue'
import userAttribution from '@/mixins/user-attribution.js'

export default {
    components: {
        ChatGptAI,
        Reviews,
        BaseFooter,
        AllNeeds,
        // Features,
        EmbraceFuture,
        GetHelp,
        DefaultLayout,
        Head,
    },
    mixins: [userAttribution],
    props: {
        videoUrl: {
            type: String,
            required: true,
        },
    },
    computed: {
        utmSource() {
            return new URLSearchParams(window.location.search).get('utm_source')
        },
    },
    async mounted() {
        this.getFbcAndFbp()

        trackEvent('start_page_shown')
        this.storeUserAttributionToLocalStorage()
    },
    unmounted() {
        localStorage.setItem('amplitudeId', amplitude.getDeviceId())
    },
    methods: {
        getURLParameter(name) {
            const params = new URLSearchParams(window.location.search)
            return params.get(name)
        },
        getFbcAndFbp() {
            this.fbc = Cookies.get('_fbc')
            this.fbp = Cookies.get('_fbp')
        },
    },
}
</script>

<style lang="scss" scoped>
.container-main {
    @apply font-sans overflow-x-hidden z-10 text-black;
    min-height: calc(100dvh - 40px);
}
</style>
